<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()

const RECAPTCHA_V2_LOAD_CB = '_recaptchaV2LoadCb'

const props = withDefaults(
  defineProps<{
    publicKey?: string
  }>(),
  {
    publicKey: ''
  }
)

const isLoaded = ref(false)
const loadPromise: any = ref(null)
const captcha: any = ref(null)
const containerRef = ref(null)

const publicKeyWithFallback = computed(() => {
  return props.publicKey || runtimeConfig.public.GOOGLE_RECAPTCHA_V2_API_KEY
})

onMounted(() => {
  loadCaptcha()
})

onBeforeUnmount(() => {
  cleanUpLoadCaptcha()
})

const render = () => {
  captcha.value = (window as any).grecaptcha.render(containerRef.value, {
    sitekey: publicKeyWithFallback.value
  })
}

const loadCaptcha = async () => {
  isLoaded.value = false
  const hid = 'g_recaptcha_v2'
  if (document.querySelector(`script[data-hid=${hid}]`)) {
    isLoaded.value = true
    render()
    return
  }

  const script = document.createElement('script')
  script.src = `https://www.google.com/recaptcha/api.js?onload=${RECAPTCHA_V2_LOAD_CB}&render=explicit&hl=ru"`
  script.defer = true
  script.async = true
  script.dataset.hid = hid
  document.head.appendChild(script)

  loadPromise.value = new Promise((resolve) => {
    (window as any)[RECAPTCHA_V2_LOAD_CB] = resolve
  })
  await loadPromise.value

  render()
  isLoaded.value = false
}

const cleanUpLoadCaptcha = () => {
  (window as any)[RECAPTCHA_V2_LOAD_CB] = undefined
}

const getRecaptchaToken = () => {
  return (window as any).grecaptcha.getResponse(captcha.value) as string
}

const reset = () => {
  return (window as any).grecaptcha.reset(captcha.value)
}

defineExpose({
  getRecaptchaToken,
  reset
})
</script>

<template>
  <div ref="containerRef" />
</template>
