<template>
  <div :class="$style.backgroundImage">
    <form
      :class="$style.form"
      @submit.prevent="handleSubmit"
    >
      <YourGoodsLogoWithoutText
        :class="$style.icon"
      />

      <h2 :class="$style.title">
        Авторизация
      </h2>
      <p :class="$style.description">
        При регистрации введите номер телефона, который будет использоваться для входа
      </p>

      <PWInputPhone
        v-model="state.username"
        placeholder="Номер телефона"
        :error="v$.username.$error"
      />
      <span
        v-if="v$.username.$error"
        :class="$style.error"
      >
        Неверный формат
      </span>

      <p v-if="otherError" :class="[$style.error, $style.big]">
        Что-то пошло не так. Напишите нам в
        <a
          :href="TG_FEEDBACK_URL"
          target="_blank"
          :class="$style.link"
        >Telegram</a>
      </p>

      <p v-if="captchaError" :class="[$style.error, $style.big]">
        Ошибка ввода капчи
      </p>

      <PWRECaptchaV2
        ref="recaptchaV2Ref"
        :class="$style.recaptcha"
      />

      <PWButton
        :class="$style.signInBtn"
        :disabled="v$.$invalid || usernameTakenLoading"
        full-width
      >
        Войти
      </PWButton>
    </form>
  </div>
</template>

<script setup lang="ts">
import debounce from 'lodash-es/debounce'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useRoute, useRouter } from 'vue-router'
import YourGoodsLogoWithoutText from 'assets/icons/YourGoodsLogoWithoutText.svg?component'
import PWInputPhone from '~/components/_ui/PWInputPhone.vue'
import PWButton from '~/components/_ui/Buttons/PWButton.vue'
import PWRECaptchaV2 from '~/components/_ui/PWRECaptchaV2.vue'
import { uniqueUsername, phoneNumber } from '~/lib/vuelidate/rules'
import { CaptchaNotPassedError, TooManyRequestsError } from '~/api/common.errors'
import { useApi } from '~/composables/useApi'
import { TG_FEEDBACK_URL } from '~/utils/constants'
import { useAuthStore } from '~/store/auth'

definePageMeta({
  name: 'signIn',
  middleware: ['already-authenticated'],
  layout: 'auth'
})
const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const api = useApi()
const runtimeConfig = useRuntimeConfig()
// const { isMobile } = useDevice()

const state = reactive({
  username: ''
})

const v$ = useVuelidate({
  username: {
    phoneNumber,
    required
  }
}, state)

const usernameTaken = ref(false)
const usernameTakenLoading = ref(false)
const otherError = ref(false)
const captchaError = ref(false)
// const TG_FEEDBACK_URL = ref(Object.freeze(TG_FEEDBACK_URL))
const recaptchaV2Ref = ref<InstanceType<typeof PWRECaptchaV2> | null>(null)

onMounted(() => {
  if (route.query.recaptchaFailed) {
    captchaError.value = true
  }

  const userInfo = {
    initRefferer: route.query.initRefferer || 'https://google.com',
    utm_campaign: route.query.utm_campaign || null,
    utm_content: route.query.utm_content || null,
    utm_medium: route.query.utm_medium || null,
    utm_source: route.query.utm_source || null,
    utm_term: route.query.utm_term || null
  }

  localStorage.setItem('profeat_user_info', JSON.stringify(userInfo))
})

async function handleUsernameChange (username: string) {
  usernameTakenLoading.value = true
  usernameTaken.value = !(await uniqueUsername(username))
  usernameTakenLoading.value = false
}
watch(() => state.username, debounce(handleUsernameChange, 300))

const handleLogin = async () => {
  try {
    const authStrategyEither = await api.authApi.checkUserAuthStrategy(state.username)
    const recaptchaToken = recaptchaV2Ref.value.getRecaptchaToken()

    if (recaptchaToken === '') {
      captchaError.value = true
      otherError.value = false
      return
    }

    authStrategyEither.mapRight(async (data: any) => {
      if (data.strategy === 'password') {
        await router.push({
          name: 'signInPassword',
          query: {
            username: state.username,
            recaptchaToken
          }
        })
      } else {
        const recaptchaToken = recaptchaV2Ref.value.getRecaptchaToken()
        const loginEither = await api.authApi.loginSendSms({
          username: state.username,
          recaptchaV2Token: recaptchaToken
        })

        loginEither.map(() => (
          router.push({
            name: 'SignInConfirm',
            query: {
              username: state.username,
              mode: 'login'
            }
          })
        ))
        loginEither.mapLeft((e: any) => {
          console.log('error in handleLogin loginEither.mapLeft((e) => {', e)
          if (e.error.code === CaptchaNotPassedError.code) {
            captchaError.value = true
          }
          if (e instanceof TooManyRequestsError) {
            otherError.value = false
          }
        })
      }
    })
  } catch (e) {
    otherError.value = true
    throw e
  }
}

const handleRegister = async () => {
  try {
    const recaptchaToken = recaptchaV2Ref.value.getRecaptchaToken()
    const sendCodeEither = await authStore.registerSendCode({
      username: state.username,
      recaptchaV2Token: recaptchaToken
    })

    sendCodeEither.mapRight(() => {
      api.statisticsInnerApi.createInnerEvent({
        name: 'register-send-success',
        userId: null,
        details: {}
      })
      window.ym(runtimeConfig.public.YANDEX_YM_ID, 'reachGoal', 'registration_verify')
      window.gtag('event', 'registerSend', {
        send_to: runtimeConfig.public.GOOGLE_GA_ID,
        event_category: 'register',
        event_label: 'Успешно отправлено сообщение с подтверждением'
      })

      router.push({
        name: 'SignInConfirm',
        query: {
          ...route.query,
          username: state.username,
          mode: 'register'
        }
      })
      otherError.value = false
    })

    sendCodeEither.mapLeft((e: any) => {
      if (e.error.code === CaptchaNotPassedError.code) {
        captchaError.value = true
        return
      }
      if (e instanceof TooManyRequestsError) {
        router.push({
          name: 'SignInConfirm',
          query: {
            ...route.query,
            username: state.username,
            mode: 'register'
          }
        })
        return
      }
      otherError.value = true
    })
  } catch (e) {
    otherError.value = true
  }
}

const handleSubmit = () => {
  if (v$.value.$invalid || usernameTakenLoading.value) {
    return
  }
  localStorage.removeItem('pf:has-integration-errors')
  usernameTaken.value ? handleLogin() : handleRegister()
}

// const handleAuthViaTelegram = async (payloadFromTelegram: Record<string, any>) => {
//   if (!payloadFromTelegram) {
//     otherError.value = false
//     return
//   }
//
//   otherError.value = false
//   const localUserInfo = localStorage.getItem('profeat_user_info')
//
//   try {
//     const userInfo = localUserInfo
//       ? JSON.parse(localUserInfo)
//       : {
//           initRefferer: 'https://google.com',
//           utm_campaign: null,
//           utm_content: null,
//           utm_medium: null,
//           utm_source: null,
//           utm_term: null
//         }
//
//     userInfo.deviceType = isMobile ? 'mobile' : 'desktop'
//
//     const authViaTelegramEither = await authStore.authViaTelegram(payloadFromTelegram, userInfo)
//
//     authViaTelegramEither.mapLeft(() => {
//       otherError.value = true
//     })
//
//     authViaTelegramEither.mapRight((data) => {
//       if (data?.isNewUser) {
//         window.gtag('event', 'registerConfirm', {
//           send_to: runtimeConfig.public.GOOGLE_GA_ID,
//           event_category: 'register',
//           event_label: 'Успешно зарегистрирован'
//         })
//         window.ym(runtimeConfig.public.YANDEX_YM_ID, 'reachGoal', 'success_registration')
//         window.ym(runtimeConfig.public.YANDEX_YM_ID, 'reachGoal', 'registerConfirm')
//         router.push({ name: 'settings-with-offer-modal' })
//       } else {
//         router.push({ name: 'visitors' })
//         window.ym(runtimeConfig.public.YANDEX_YM_ID, 'reachGoal', 'success_login')
//       }
//     })
//   } catch (e) {
//     console.error(e)
//     otherError.value = true
//   }
// }
</script>

<style module lang="scss">
.backgroundImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-image: url("assets/default-images/DefaultBackgroundSignIn.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.form {
  background: white;
  border-radius: 8px;
  border: 1px solid var(--grey-300);
  padding: 40px;
  max-width: 480px;
  margin: 0 auto;
}

.title {
  text-align: center;
  margin: 0 0 16px;
}

.description {
  text-align: center;
  margin: 0 0 24px;
  font-size: 14px;
}

.icon {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
}

.signInBtn {
  margin-top: 24px;
}

.signInViaTelegramBtn {
  color: var(--white) !important;
  font-weight: normal;
  background-color: #54a9eb !important;
}

.signInViaTelegramBtnIcon {
  margin-right: 8px;
}

.signInSeparator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin: 24px 0;
  font-size: 12px;
}

.signInSeparatorLine {
  flex: 1 1 auto;
  width: 100%;
  border: 0.5px solid var(--grey-300);
}

.error {
  margin: 0 0 0 12px;
  font-size: 12px;
  color: var(--main-red);
  font-weight: 400;
  line-height: 20px;
}

.link {
  color: var(--main-blue);
  text-decoration: none;

  &:visited {
    color: var(--main-blue);
  }
}

.big {
  text-align: center;
  font-size: 14px;
  margin: 16px 0 0 0;
}

.recaptcha {
  margin-top: 12px;
}

</style>
